'use client';
import cn from 'clsx';
import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/navigation';

import { GlobalItem, GlobalMM2Item } from '@/shared/types/common';
import { Tabs } from '@/shared/ui/tabs';
import { useStore } from '@/store/context';

import styles from './select-pet-properties.module.scss';

type SelectPetPropertiesFeatureProps = {
  item: GlobalItem | GlobalMM2Item;
};

export const SelectPetPropertiesFeature = observer(
  ({ item }: SelectPetPropertiesFeatureProps) => {
    const shop = useStore()?.shop;

    const tabs =
      'properties' in item &&
      Array?.isArray(item?.properties) &&
      item?.properties?.length > 0
        ? item?.properties
        : [];

    const defaultActiveTab = item?.propertiesSetId;

    const router = useRouter();
    if (!('properties' in item)) {
      return (
        <Tabs<string | number>
          defaultActiveTab={defaultActiveTab}
          onTabsChange={propId => {
            shop?.setPetProperties(item, propId);
          }}
        >
          <Tabs.ItemCard
            className={cn(styles['tab-item'])}
            value={'menu'}
            onClick={() => router.push(`/shop/${item.category}/${item?.id}`)}
            tagSize={18}
            tagsCategory={['menu']}
          />
        </Tabs>
      );
    }

    return (
      Array.isArray(tabs) &&
      tabs.length > 0 && (
        <Tabs<string | number>
          defaultActiveTab={defaultActiveTab}
          onTabsChange={propId => {
            shop?.setPetProperties(item, propId);
          }}
        >
          {tabs.map((tab, idx) => {
            return (
              <Tabs.ItemCard
                key={`tab.value-${idx}`}
                className={cn(styles['tab-item'])}
                value={tab?.id ?? tab?.value}
                tagSize={18}
                tagsCategory={tab?.tagCategory}
                tagsRarity={tab?.tagRarity}
              />
            );
          })}

          <Tabs.ItemCard
            className={cn(styles['tab-item'])}
            value={'menu'}
            onClick={() => router.push(`/shop/${item.category}/${item?.id}`)}
            tagSize={18}
            tagsCategory={['menu']}
          />
        </Tabs>
      )
    );
  },
);

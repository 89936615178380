import { Filter } from '@/shared/types/common';

/*
export const PROPERTY_LABELS = [
  'Default',
  'Neon',
  'Mega-Neon',
  'Fly',
  'Ride',
  'Pets',
  'Eggs',
  'Transport',
  'Potion',
  'Exclusive',
];
export const RARITY_LABELS = [
  'Common',
  'Uncommon',
  'Rare',
  'Ultra-Rare',
  'Legendary',
];
*/

export const HOT_SALE_FILTERS: Filter[] = [
  {
    label: 'Hot Sale',
    filter: 'hot-sale',
    group: 'sale',
  },
];
export const NEW_FILTERS: Filter[] = [
  {
    label: 'New',
    filter: 'new',
    icon: 'lightning',
    group: 'category',
  },
];

export const PROPERTIES_FILTERS: Filter[] = [
  {
    group: 'property',
    label: 'Default',
    icon: 'none',
    filter: 'default',
  },
  {
    group: 'property',
    label: 'Neon',
    icon: 'neon',
    filter: 'neon',
  },
  {
    group: 'property',
    label: 'Mega-Neon',
    icon: 'mega-neon',
    filter: 'mega-neon',
  },
  {
    group: 'property',
    label: 'Fly',
    icon: 'fly',
    filter: 'fly',
  },
  {
    group: 'property',
    label: 'Ride',
    icon: 'ride',
    filter: 'ride',
  },
];
export const CATEGORY_FILTERS: Filter[] = [
  {
    group: 'category',
    label: 'Pets',
    icon: 'cat',
    filter: 'pets',
  },
  {
    group: 'category',
    label: 'Eggs',
    icon: 'egg',
    filter: 'eggs',
  },
  {
    group: 'category',
    label: 'Transport',
    icon: 'bus',
    filter: 'transport',
  },
  {
    group: 'category',
    label: 'Potion',
    icon: 'potion',
    filter: 'potions',
  },
  {
    group: 'category',
    label: 'Exclusive',
    icon: 'exclusive',
    filter: 'exclusive',
  },
];
export const RARITY_FILTERS: Filter[] = [
  {
    group: 'rarity',
    label: 'Common',
    icon: 'common',
    filter: 'common',
  },
  {
    label: 'Uncommon',
    icon: 'uncommon',
    filter: 'uncommon',
    group: 'rarity',
  },
  {
    label: 'Rare',
    icon: 'rare',
    filter: 'rare',
    group: 'rarity',
  },
  {
    label: 'Ultra-Rare',
    icon: 'ultra-rare',
    filter: 'ultra-rare',
    group: 'rarity',
  },
  {
    label: 'Legendary',
    icon: 'legendary',
    filter: 'legendary',
    group: 'rarity',
  },
];

export const RARITY_MM2_FILTERS: Filter[] = [
  {
    label: 'Unique',
    icon: 'unique',
    filter: 'unique',
    group: 'rarity',
    color: '#6941C6',
  },
  {
    label: 'Ancient',
    icon: 'ancient',
    filter: 'ancient',
    group: 'rarity',
    color: '#FAFE4D',
  },
  {
    label: 'Godly',
    icon: 'godly',
    filter: 'godly',
    group: 'rarity',
    color: '#FE9920',
  },
  {
    label: 'Legendary',
    icon: 'legendary',
    filter: 'legendary',
    group: 'rarity',
    color: '#D41057',
  },
  {
    label: 'Rare',
    icon: 'rare',
    filter: 'rare',
    group: 'rarity',
    color: '#40BB18',
  },
  {
    group: 'rarity',
    label: 'Common',
    icon: 'common',
    filter: 'common',
    color: '#303030',
  },
  {
    label: 'Uncommon',
    icon: 'uncommon',
    filter: 'uncommon',
    group: 'rarity',
    color: '#4891FF',
  },
  {
    label: 'Vintage',
    icon: 'vintage',
    filter: 'vintage',
    group: 'rarity',
    color: '#EB3C3C',
  },
];

export const CHROMA_FILTERS: Filter[] = [];

export const AGE_FILTERS: Filter[] = [
  {
    group: 'age',
    label: 'Newborn',
    filter: 'newborn',
  },
  {
    group: 'age',
    label: 'Junior',
    filter: 'junior',
  },
  {
    group: 'age',
    label: 'Pre-teen',
    filter: 'pre-teen',
  },
  {
    group: 'age',
    label: 'Teen',
    filter: 'teen',
  },
  {
    group: 'age',
    label: 'Post-teen',
    filter: 'post-teen',
  },
  {
    group: 'age',
    label: 'Full-grow',
    filter: 'full-grow',
  },
  {
    group: 'age',
    label: 'Reborn',
    filter: 'reborn',
  },
  {
    group: 'age',
    label: 'Twinkle',
    filter: 'twinkle',
  },
  {
    group: 'age',
    label: 'Sparkle',
    filter: 'sparkle',
  },
  {
    group: 'age',
    label: 'Flare',
    filter: 'flare',
  },
  {
    group: 'age',
    label: 'Sunshine',
    filter: 'sunshine',
  },
  {
    group: 'age',
    label: 'Luminous',
    filter: 'luminous',
  },
];

export const PRICE_FILTERS: Filter[] = [
  {
    group: 'priceFrom',
    label: '',
    filter: '',
  },
  {
    group: 'priceTo',
    label: '',
    filter: '',
  },
];

export const CATEGORIES_MM2_FILTERS: Filter[] = [
  {
    group: 'category',
    label: 'Knives',
    filter: 'mm2-knives',
  },
  {
    group: 'category',
    label: 'Guns',
    filter: 'mm2-guns',
  },
  {
    group: 'category',
    label: 'Bundles',
    filter: 'mm2-bundles',
  },
  {
    group: 'category',
    label: 'New',
    filter: 'mm2-new',
  },
  {
    group: 'category',
    label: 'Exclusive',
    filter: 'mm2-exclusive',
  },
  {
    group: 'category',
    label: 'Discount',
    filter: 'mm2-discount',
  },
  {
    group: 'category',
    label: 'Under 3.5$',
    filter: 'mm2-cheap',
  },
  {
    group: 'category',
    label: 'Chroma',
    filter: 'mm2-chroma-knives',
  },
  {
    group: 'category',
    label: 'Pets',
    filter: 'mm2-pets',
  },
];

import cn from 'clsx';

import { ItemRarityCard } from '@/shared/ui';
import { CardItemInfo, CardItemTop } from '@/shared/ui/cards';
import { itemVariantsMM2 } from '@/shared/ui/item/item.types';
import {
  colorsByVariant,
  colorsMM2Variant,
} from '@/shared/ui/item-rarity-card/item-rarity-card';

import cardItemInfoStyles from './card-item-info.module.scss';
import styles from './card-item-shop.module.scss';
import { CardItemShopEntityProps } from './card-item-shop.types';
import cardItemTopStyles from './card-item-top.module.scss';

export const CardItemShopEntityMobile = ({
  children,
  className,
  rarity,
  info,
  tagsInfo,
  item,
  bottomSlot,
  topRightSlot,
  onClick = () => {},
  propertiesSlot,
  ...props
}: CardItemShopEntityProps) => {
  const cardItemShopClasses = cn(styles['card-item-shop'], className);
  const colorScheme = itemVariantsMM2.includes(item)
    ? colorsMM2Variant
    : colorsByVariant;

  return (
    <article className={cardItemShopClasses} {...props}>
      <CardItemTop
        onClick={onClick}
        customStyles={cardItemTopStyles}
        item={item}
        tagsInfo={tagsInfo}
        topRightSlot={topRightSlot}
        backgroundIllustration={
          <ItemRarityCard
            colorScheme={colorScheme}
            className={styles['item-rarity-card']}
            size={'100'}
            variant={rarity}
          />
        }
      />

      <CardItemInfo
        onTitleClick={onClick}
        customStyles={cardItemInfoStyles}
        info={info}
      />

      {propertiesSlot && (
        <div className={styles['properties-slot']}>{propertiesSlot}</div>
      )}

      <div className={styles['bottom-slot']}>{bottomSlot}</div>
    </article>
  );
};

//@TODO попробовать передавать CardItemTop через слот topSlot напр,
// а то 10 пропсов для сущности это овер (тоже самое в CardItem для десктопа)

import cn from 'clsx';

import {
  Item,
  ItemRarityCard,
  TagCategory,
  TagRarity,
  Typography,
} from '@/shared/ui';
import { itemVariantsMM2 } from '@/shared/ui/item/item.types';
import {
  colorsByVariant,
  colorsMM2Variant,
} from '@/shared/ui/item-rarity-card/item-rarity-card';

import styles from './card-item-live.module.scss';
import {
  CardItemLiveItemInfoProps,
  CardItemLiveItemPropertiesProps,
  CardItemLiveItemProps,
  CardItemLiveProps,
} from './card-item-live.types';

export const CardItemLiveEntityMobile = ({
  className,
  leftSlot,
  middleSlot,
  rightSlot,
  ...props
}: CardItemLiveProps) => {
  return (
    <article className={cn(styles['card-item-live'], className)} {...props}>
      {leftSlot}
      {middleSlot}
      {rightSlot}
    </article>
  );
};

CardItemLiveEntityMobile.Item = ({
  className,
  rarity,
  item,
  ...props
}: CardItemLiveItemProps) => {
  const colorScheme = itemVariantsMM2.includes(item)
    ? colorsMM2Variant
    : colorsByVariant;

  return (
    <div
      className={cn(
        styles['item-container'],
        styles['item-paddings'],
        className,
      )}
      {...props}
    >
      <ItemRarityCard
        colorScheme={colorScheme}
        className={cn(styles['item-rarity'], styles['item-paddings'])}
        size={'100%'}
        variant={rarity}
      />
      <Item className={styles['item']} size={'48'} variant={item} />
    </div>
  );
};

CardItemLiveEntityMobile.ItemInfo = ({
  title,
  age,
  hasBundles,
}: CardItemLiveItemInfoProps) => {
  return (
    <div className={styles['item-info-container']}>
      <div className={styles['info']}>
        {title && <Typography className={styles['title']}>{title}</Typography>}
        {age && <Typography className={styles['age']}>{age}</Typography>}
        {hasBundles && (
          <Typography className={styles['age']}>Bundles</Typography>
        )}
      </div>
    </div>
  );
};

CardItemLiveEntityMobile.ItemProperties = ({
  tagCategory,
  rarity,
  item,
  ...props
}: CardItemLiveItemPropertiesProps) => {
  const colorScheme: { [key: string]: string } = itemVariantsMM2.includes(item)
    ? colorsMM2Variant
    : colorsByVariant;

  return (
    <div className={styles['item-properties-container']} {...props}>
      {rarity && (
        <TagRarity
          color={colorScheme[rarity]}
          variant={rarity}
          className={styles['rarity']}
        />
      )}
      {tagCategory?.map((tag, idx) => {
        return (
          <TagCategory
            className={styles['category']}
            key={`category-${tag}-${idx}`}
            variant={tag}
          />
        );
      })}
    </div>
  );
};

'use client';
import { Game } from '@utils/AppConfig';
import { sleep } from '@utils/delay';
import cn from 'clsx';
import { observer } from 'mobx-react-lite';
import { router } from 'next/client';
import { usePathname, useRouter } from 'next/navigation';
import React, { useEffect, useState } from 'react';

import { DropList } from '@/shared/components/input-search/types';
import { IllustrationGame } from '@/shared/ui';
import { Illustration } from '@/shared/ui';
import { DropDownWrapper } from '@/shared/ui/desktop/drop-down-wrapper/drop-down-wrapper';
import { DropListItem } from '@/shared/ui/drop-list-item';
import { BottomSheetUi } from '@/shared/ui/mobile/bottom-sheet';
import { useStore } from '@/store/context';

import styles from './drop-list-game.module.scss';
import { DropListGameProps } from './types';

const games: DropList<Game>[] = [
  {
    label: 'Adopt Me',
    value: 'adopt',
    icon: <IllustrationGame variant={'adopt'} size={32} />,
  },
  {
    label: 'MM2',
    value: 'mm2',
    icon: <IllustrationGame variant={'mm2'} size={32} />,
  },
];

export const DropListGame: React.FC<DropListGameProps> = observer(
  ({ isMobile, hideTitle, alternativeHeaderClasses }) => {
    const selectedGame = useStore()?.app.selectedGame;
    const [selectedGameInfo, setSelectedGameInfo] = useState(
      games.find(game => game.value === selectedGame),
    );

    useEffect(() => {
      setSelectedGameInfo(games.find(game => game.value === selectedGame));
    }, [selectedGame]);

    const [opened, setOpened] = useState(false);
    const appStore = useStore().app;

    const headerClasses = cn(styles['container-header'], {
      [styles['container-header-opened']]: opened,
      [styles['hidden-title']]: alternativeHeaderClasses,
    });
    const iconClasses = cn(styles.icon, {
      [styles['icon-active']]: opened,
      [styles['hidden-title']]: alternativeHeaderClasses,
    });
    const pathname = usePathname();
    const router = useRouter();
    const shouldRedirectToMain =
      RegExp('^\\/([a-zA-Z]{2})\\/shop\\/.*$').test(pathname) ||
      RegExp('^\\/([a-zA-Z]{2})\\/categories(?:\\/.*)?$').test(pathname);

    const toggleClasses = cn(styles.game_item, styles.no_padding, {
      [styles['hidden-title']]: alternativeHeaderClasses,
    });

    const setGame = (item: DropList<Game>) => {
      setSelectedGameInfo(
        games.find(game => game.value === item.value) || games[0],
      );
      appStore.setGame(item.value);
      setOpened(false);
    };

    const onSelect = (item: DropList<Game>) => {
      if (shouldRedirectToMain && selectedGame !== item.value) {
        router.push('/');
        sleep(500).then(() => {
          setGame(item);
        });
      } else {
        setGame(item);
      }
    };

    const handleClose = () => {
      setOpened(false);
    };

    const renderGameItem = (item: DropList<Game>, index: number) => {
      return (
        <DropListItem
          key={index}
          isSelected={isMobile && item.value === selectedGameInfo?.value}
          variant={'game'}
          onPress={() => onSelect(item)}
          title={item.label}
          iconLeft={item.icon}
          className={styles.game_item}
        />
      );
    };

    return (
      <div className={styles.container}>
        <button className={headerClasses} onClick={() => setOpened(true)}>
          <DropListItem
            variant={'game'}
            iconLeft={selectedGameInfo?.icon}
            title={hideTitle ? '' : (selectedGameInfo?.label ?? '')}
            className={toggleClasses}
            onPress={() => {}}
          />
          <Illustration
            spanTagClassName={iconClasses}
            name={'chevron-down'}
            style={{ color: '#BFBFBF' }}
            size={18}
            spriteName={'icons'}
          />
        </button>
        {isMobile ? (
          <BottomSheetUi isOpen={opened} handleClose={handleClose}>
            <BottomSheetUi.Content className={styles.sheet}>
              <BottomSheetUi.Header>Games</BottomSheetUi.Header>
              <div className={styles['sheet-items']}>
                {games.map(renderGameItem)}
              </div>
              <BottomSheetUi.Button text={'Close'} onClick={handleClose} />
            </BottomSheetUi.Content>
          </BottomSheetUi>
        ) : (
          <DropDownWrapper
            close={handleClose}
            isOpened={opened}
            className={styles.drop_down}
          >
            <>{games.map(renderGameItem)}</>
          </DropDownWrapper>
        )}
      </div>
    );
  },
);

'use client';
import {
  CardInventoryEntityMobileDefaultProps,
  CardInventoryEntityMobileGiftProps,
  CardInventoryEntityMobileLimitedProps,
  CardInventoryItemInfoProps,
  CardInventoryItemPropertiesProps,
  CardInventoryItemProps,
  CardInventoryProps,
} from '@entities/mobile/cards/card-inventory/ui/card-inventory.types';
import cn from 'clsx';
import { motion } from 'framer-motion';
import React, { useState } from 'react';

import { WithCurrency } from '@/shared/hocs';
import {
  Item,
  ItemRarityCard,
  ProgressIndicator,
  TagCategory,
  TagRarity,
  Typography,
} from '@/shared/ui';
import Checkbox from '@/shared/ui/desktop/check-box/check-box';
import { itemVariantsMM2 } from '@/shared/ui/item/item.types';
import {
  colorsByVariant,
  colorsMM2Variant,
} from '@/shared/ui/item-rarity-card/item-rarity-card';
import { AdditionalInfoMobile } from '@/shared/ui/mobile/additional-info';

import styles from './card-inventory.module.scss';

export const CardInventoryEntityMobile = ({
  className,
  topMiddleSlot,
  topRightSlot,
  topLeftSlot,
  bottomLeftSlot,
  bottomRightSlot,
  disabled,
  ...props
}: CardInventoryProps) => {
  return (
    <motion.article
      className={cn(
        styles['card-inventory'],
        { [styles['disabled']]: disabled },
        className,
      )}
      {...props}
    >
      <div className={styles['top-slot']}>
        {topLeftSlot}
        {topMiddleSlot}
        <div className={styles['top-slot--right']}>{topRightSlot}</div>
      </div>
      <div id={'bottom-slot'} className={styles['bottom-slot']}>
        {bottomLeftSlot}
        {bottomRightSlot}
      </div>
    </motion.article>
  );
};

CardInventoryEntityMobile.Item = ({
  className,
  rarity,
  item,
  ...props
}: CardInventoryItemProps) => {
  const colorScheme = itemVariantsMM2.includes(item)
    ? colorsMM2Variant
    : colorsByVariant;

  return (
    <div
      className={cn(
        styles['item-container'],
        styles['item-paddings'],
        className,
      )}
      {...props}
    >
      <ItemRarityCard
        colorScheme={colorScheme}
        className={cn(styles['item-rarity'], styles['item-paddings'])}
        size={'100%'}
        variant={rarity}
      />
      <Item className={styles['item']} size={'48'} variant={item} />
    </div>
  );
};

CardInventoryEntityMobile.ItemInfo = ({
  title,
  price: { current, old },
}: CardInventoryItemInfoProps) => {
  return (
    <div className={styles['item-info-container']}>
      <div className={styles['info']}>
        <div className={styles['price']}>
          {current && (
            <Typography className={styles['price--current']}>
              <WithCurrency>{current}</WithCurrency>
            </Typography>
          )}
          {old && (
            <Typography
              decoration={'line-through'}
              className={styles['price--old']}
            >
              <WithCurrency>{old}</WithCurrency>
            </Typography>
          )}
        </div>
        {title && <Typography className={styles['title']}>{title}</Typography>}
      </div>
    </div>
  );
};

CardInventoryEntityMobile.ItemProperties = ({
  tagCategory,
  rarity,
  ...props
}: CardInventoryItemPropertiesProps) => {
  return (
    <div className={styles['item-properties-container']} {...props}>
      {rarity && <TagRarity variant={rarity} className={styles['rarity']} />}
      {tagCategory?.map((tag, idx) => {
        return (
          <TagCategory
            className={styles['category']}
            key={`category-${tag}-${idx}`}
            variant={tag}
          />
        );
      })}
    </div>
  );
};

CardInventoryEntityMobile.SpendingProgress = ({
  price,
}: {
  price: { current: number; total: number };
}) => {
  return (
    <div className={styles['spending-progress']}>
      <div className={styles['price']}>
        <Typography className={styles['current']}>
          <WithCurrency>{price.current}</WithCurrency>
        </Typography>
        <Typography className={styles['total']}>/ {price.total}</Typography>
      </div>

      <ProgressIndicator
        staticWidth={(price.current / price.total) * 100}
        variant={'warn'}
      />

      <Typography className={styles['content']}>
        To purchase this item, you need to spend at least{' '}
        <WithCurrency>{price.total}</WithCurrency> on other items
      </Typography>
    </div>
  );
};

CardInventoryEntityMobile.Limited = ({
  tagCategory,
  rarity,
  item,
  title,
  price,
  game,
  spendQuantityTotal,
  spendQuantityCurrent,
  claimWithin,
}: CardInventoryEntityMobileLimitedProps) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [isTapped, setIsTapped] = useState<boolean>(false);

  return (
    <CardInventoryEntityMobile
      onTap={() => setIsTapped(prev => !prev)}
      topLeftSlot={
        isTapped ? (
          <CardInventoryEntityMobile.SpendingProgress
            price={{ total: spendQuantityTotal, current: spendQuantityCurrent }}
          />
        ) : (
          <CardInventoryEntityMobile.Item
            game={game}
            item={item}
            rarity={rarity}
          />
        )
      }
      topMiddleSlot={
        isTapped ? null : (
          <CardInventoryEntityMobile.ItemInfo
            title={title}
            price={{ old: price.old, current: price.current }}
          />
        )
      }
      topRightSlot={
        <>
          <CardInventoryEntityMobile.ItemProperties
            tagCategory={tagCategory}
            rarity={rarity}
          />
        </>
      }
      bottomRightSlot={
        <AdditionalInfoMobile.Timer
          duration={claimWithin}
          status={'claim'}
          width={'full-w'}
          enableColorDiffByTime
        />
      }
      bottomLeftSlot={
        <AdditionalInfoMobile.Card
          icon={'gift'}
          variant={'warn'}
          width={'full-w'}
          leftText={'Limited'}
          rightText={`${spendQuantityCurrent}/${spendQuantityTotal}$`}
        />
      }
    />
  );
};
CardInventoryEntityMobile.Gift = ({
  tagCategory,
  rarity,
  item,
  title,
  price,
  claimWithin,
  checked,
  timesUp,
  handleTimesUp,
  toggleSelected,
  disabled,
  game,
  ...props
}: CardInventoryEntityMobileGiftProps) => {
  return (
    <CardInventoryEntityMobile
      disabled={disabled}
      onClick={props.onClick}
      topLeftSlot={
        <CardInventoryEntityMobile.Item
          game={game}
          item={item}
          rarity={rarity}
        />
      }
      topMiddleSlot={
        <CardInventoryEntityMobile.ItemInfo
          title={title}
          price={{ old: price.old, current: price.current }}
        />
      }
      topRightSlot={
        <>
          <CardInventoryEntityMobile.ItemProperties
            tagCategory={tagCategory}
            rarity={rarity}
          />
          {!timesUp && <Checkbox checked={checked} onChange={toggleSelected} />}
        </>
      }
      bottomRightSlot={
        <AdditionalInfoMobile.Timer
          duration={claimWithin}
          enableColorDiffByTime
          status={'claim'}
          width={'full-w'}
          onTimesOut={handleTimesUp}
        />
      }
      bottomLeftSlot={
        <AdditionalInfoMobile.DefaultIcon
          variant={'success'}
          withBackground
          name={'gift'}
        />
      }
    />
  );
};

CardInventoryEntityMobile.Default = ({
  tagCategory,
  rarity,
  item,
  title,
  price,
  claimWithin,
  checked,
  timesUp,
  handleTimesUp,
  toggleSelected,
  disabled,
  game,
  ...props
}: CardInventoryEntityMobileDefaultProps) => {
  return (
    <CardInventoryEntityMobile
      onClick={props.onClick}
      topLeftSlot={
        <CardInventoryEntityMobile.Item
          game={game}
          item={item}
          rarity={rarity}
        />
      }
      topMiddleSlot={
        <CardInventoryEntityMobile.ItemInfo
          title={title}
          price={{ old: price.old, current: price.current }}
        />
      }
      topRightSlot={
        <>
          <CardInventoryEntityMobile.ItemProperties
            tagCategory={tagCategory}
            rarity={rarity}
          />
          {!timesUp && <Checkbox checked={checked} onChange={toggleSelected} />}
        </>
      }
      bottomRightSlot={
        <AdditionalInfoMobile.Timer
          duration={claimWithin}
          status={'claim'}
          width={'full-w'}
          onTimesOut={handleTimesUp}
        />
      }
    />
  );
};

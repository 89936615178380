import cn from 'clsx';

import { Item } from '@/shared/ui';
import { ItemRarityItemPage } from '@/shared/ui';
import { itemVariantsMM2 } from '@/shared/ui/item/item.types';
import {
  colorsByVariant,
  colorsMM2Variant,
} from '@/shared/ui/item-rarity-item-page/item-rarity-item-page';

import styles from './card-item-block.module.scss';
import { CardItemBlockMobileProps } from './card-item-block.types';
export const CardItemBlockEntityMobile = ({
  children,
  className,
  item,
  rarity,
  bottomSlot,
  ...props
}: CardItemBlockMobileProps) => {
  const cardLiveFeedClasses = cn(styles['card-item-block'], className);

  const isMM2Variant = itemVariantsMM2.includes(item);
  const colorScheme = isMM2Variant ? colorsMM2Variant : colorsByVariant;

  return (
    <article className={cardLiveFeedClasses} {...props}>
      <ItemRarityItemPage
        isMM2Background={isMM2Variant}
        colorScheme={colorScheme}
        isDesktop={false}
        className={styles['item-rarity']}
        variant={rarity}
      />
      <Item className={styles['item']} size={'124'} variant={item} />

      {bottomSlot && <div className={styles['bottom-slot']}>{bottomSlot}</div>}
    </article>
  );
};
